import "./App.css"; 
import Homepage from "./components/Homepage";
import "./assets/css/style.css";
import Aboutus from "./components/About";
import Footer from "./components/Footer";
import Ourwork from "./components/Ourwork";
import Service from "./components/Service";
import Blog_events from "./components/Blog_events";
import Whatwedo from "./components/Whatwedo";
import Header from "./components/Header";
import { Route, Routes } from "react-router-dom";
import axios from "axios";
import Contactus from "./components/Contactus";
import Forsuppliers from "./components/Forsuppliers";
import Careers from "./components/Careers";
import Maps from "./components/Maps";
import { useEffect, useState } from "react";
import ScrollToTop from "./components/ScrollTop";
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import translationAR from "./components/Lang/translationAR.json";
import translationEN from "./components/Lang/translationEN.json";

axios.defaults.baseURL = "https://timeent.rvamp.com/api/v1";
export const baseurl = "https://timeent.rvamp.com/storage/";
// axios.defaults.baseURL = "http://127.0.0.1:8000/api/v1";
// export const baseurl = "http://127.0.0.1:8000/storage/";
axios.defaults.headers.post["Content-Type"] = "application/json";

function App() {
  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("lng") || "en"
  );

  useEffect(() => {
    localStorage.setItem("lng", currentLang);
    document.documentElement.setAttribute(
      "dir",
      currentLang === "ar" ? "rtl" : "ltr"
    );
  }, [currentLang]);

  const resources = {
    en: {
      translation: translationEN,
    },
    ar: {
      translation: translationAR,
    },
  };

  i18n.use(initReactI18next).init({
    resources,
    lng: currentLang,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

  const changeLanguage = (lng) => {
    setCurrentLang(lng);
    i18n.changeLanguage(lng);
    document.documentElement.setAttribute("dir", lng === "ar" ? "rtl" : "ltr");
    window.location.href = window.location.href;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <ScrollToTop>
        <Header currentLang={currentLang} handleLangChange={changeLanguage} />
        <Routes>
          <Route path="/" element={<Homepage currentLang={currentLang} />} />
          <Route
            path="/whatwedo"
            element={<Whatwedo currentLang={currentLang} />}
          />
          <Route
            path="/whatwedo/:id"
            element={<Whatwedo currentLang={currentLang} />}
          />
          <Route
            path="/ourwork"
            element={<Ourwork currentLang={currentLang} />}
          />
          <Route
            path="/service"
            element={<Service currentLang={currentLang} />}
          />
          <Route
            path="/service/:id"
            element={<Service currentLang={currentLang} />}
          />
          <Route
            path="/aboutus"
            element={<Aboutus currentLang={currentLang} />}
          />
          {/* <Route path="/Blogs_evenets/:id" element={<Blog_events />} /> */}
          <Route path="/contacts" element={<Contactus />} />
          <Route path="/forsuppliers" element={<Forsuppliers />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/ourloaction" element={<Maps />} />
        </Routes>
        <Footer currentLang={currentLang} />
      </ScrollToTop>
    </div>
  );
}

export default App;
