import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseurl } from "../App";

const About = ({ currentLang }) => {
  const [about, setabout] = useState([]);

  useEffect(() => {
    AboutActiveapi();
  }, []);

  const AboutActiveapi = async () => {
    try {
      let response = await axios.get(`/admin/getactiveabout`);
      console.log(response);
      setabout(response.data.abouts);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="about">
      <>
        {about.map((item) => {
          return (
            <>
              <section
                className="text-center aboutus"
                id="about"
                style={{
                  background: "#000000",
                  backgroundSize: "cover, auto",
                  paddingBottom: 0,
                }}
              >
                <div className="container">
                  <div className="row homepage_about">
                    <div
                      className="col-md-6 col-12 abtus-content order-2 order-md-1"
                      style={{ position: "relative" }}
                      data-aos="fade-right">
                      <div className="dd-titlediv ">
                        <h1 className="abt-title smallwhite-title wwe-title">
                          {currentLang === "en"
                            ? item.title_1
                            : item.title_1_ar}
                        </h1>
                        <h1 className="tpdb-title tpdb-title-abt abt-title wwe-title">
                          {currentLang === "en"
                            ? item.title_1
                            : item.title_1_ar}
                        </h1>
                      </div>
                      <p className="artext p-abttext wwd-text">
                        {currentLang === "en"
                          ? item.content_1
                          : item.content_1_ar}
                      </p>
                    </div>
                    <div className="col-md-6 col-12 abtus-content order-1 order-md-2">
                      <img
                        alt=""
                        className="imgvr"
                        data-aos="fade-left"
                        src={`${baseurl}${item.image_1}`}
                      />
                    </div>
                  </div>
                </div>
              </section>

              <section
                className="text-center about_mission"
                id="about"
                style={{
                  background: "#000000",
                  backgroundSize: "cover, auto",
                  paddingBottom: 0,
                }}
              >
                <div className="container">
                  <div className="row homepage_about">
                    <div className="col-md-6 col-xs-12 abtus-content">
                      <img
                        alt=""
                        className="imgvr"
                        data-aos="fade-left"
                        src={`${baseurl}${item.image_3}`}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div
                      className="col-md-6 col-xs-12 abtus-content mt-3"
                      style={{ position: "relative" }}
                      data-aos="fade-right"
                    > 
                      <div className="dd-titlediv ">
                        <h1 className="abt-title smallwhite-title wwe-title">
                          {currentLang === "en"
                            ? item.title_3
                            : item.title_3_ar}
                        </h1>
                        <h1 className="tpdb-title tpdb-title-abt abt-title wwe-title">
                          {currentLang === "en"
                            ? item.title_3
                            : item.title_3_ar}
                        </h1>
                      </div>
                      <p className="artext p-abttext wwd-text">
                        {currentLang === "en"
                          ? item.content_3
                          : item.content_3_ar}
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section
                className="text-center about_mission"
                id="about"
                style={{
                  background: "#000000",
                  backgroundSize: "cover, auto",
                  paddingBottom: 0,
                }}
              >
                <div className="container">
                  <div className="row homepage_about">
                    <div
                      className="col-md-6 col-12 abtus-content order-2 order-md-1"
                      style={{ position: "relative" }}
                      data-aos="fade-right"
                    >
                      <div className="dd-titlediv">
                        <h1 className="abt-title smallwhite-title wwe-title">
                          {currentLang === "en"
                            ? item.title_2
                            : item.title_2_ar}
                        </h1>
                        <h1 className="tpdb-title tpdb-title-abt abt-title wwe-title">
                          {currentLang === "en"
                            ? item.title_2
                            : item.title_2_ar}
                        </h1>
                      </div>
                      <p className="artext p-abttext wwd-text">
                        {currentLang === "en"
                          ? item.content_2
                          : item.content_2_ar}
                      </p>
                    </div>
                    <div className="col-md-6 col-12 abtus-content order-1 order-md-2">
                      <img
                        alt=""
                        className="imgvr"
                        data-aos="fade-left"
                        src={`${baseurl}${item.image_2}`}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </>
          );
        })} 
      </>
    </div>
  );
};

export default About;
