
import React, { useState } from "react";
import blog_1 from "../assets/img/blog1.png";
import axios from "axios";

const Careers = ({ currentLang }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState(""); 

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleCareers = (e) => {
    e.preventDefault();
  
    const formdata = new FormData();
  
    formdata.append("file", file);
    formdata.append("email", email);
    formdata.append("name", name);
    formdata.append("msg", message);
  
    axios
      .post('/admin/send-email', formdata, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => {
        console.log("res", res);
        setName("");
        setEmail("");
        setMessage("");
        setFile(null);
        e.target.reset(); // reset the form to clear all input fields
        document.getElementById("EmailSent").style.display = "block"; // show the message
      })
      .catch((error) => {
        console.log(error);
      });
  };
  

  return (
    <section className="contactus for_supplers careerspadding">
      <div
        className="container"
        style={{ position: "relative" }}
        data-aos="fade-right"
      >
        <div className="dd-titlediv">
          <h1 className="title_head_1 pd20 wwe-title smallwhite-title">
            Careers
          </h1>
          <h1 className="title_head_2 tpdb-title wwe-title smallwhite-title">
            Careers
          </h1>
        </div>
      </div>
      <div className="row careerspadding " data-aos="fade-right">
        <div className="col-md-6">
          <form onSubmit={handleCareers} encType="multipart/form-data" method="post">
            <div className="form">
              <input
                type="text"
                placeholder=" Name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="form-control"
                required
              />
              <input
                type="text"
                placeholder="Email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                required
              />
              <input
                type="file"
                placeholder="Updated CV"
                name="file"
                onChange={handleFileChange}
                className="form-control"
                required
              />  


              <textarea
                placeholder="Message"
                className="form-control"
                name="message"
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows={5}
                required
              />
              <button type="submit">
                Send <i className="arrow right" />{" "}
              </button>
              <h4 id="EmailSent" style={{ display: "none",color:"lightgreen" }}>Email sent Successfully.</h4>
            </div>
          </form>
        </div>
        <div className="col-md-6">
          <div className="right_image">
            <img src={blog_1} style={{ width: "100%" }} className="supplers" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Careers; 