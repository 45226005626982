import React, { useState, useEffect } from "react";
import { baseurl } from "../App";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Whatwedo = ({ currentLang }) => {
  const navigate = useNavigate();

  let { t } = useTranslation();

  const [whatwedo_data, setwhatwedo_data] = useState({});
  const [service_data, setservice_data] = useState([]);
  const [loading, setloading] = useState(false);
  const [load, setload] = useState(false);
  const [service_first, setService_first] = useState({});

  useEffect(() => {
    Whatwedo();
    FetchPost();
  }, []);

  let { id } = useParams();

  const FetchPost = async () => {
    try {
      let response = await axios.get(`/admin/serviceById/${id}`);
      setwhatwedo_data(response.data.services);
      console.log("ser", setService_first);
      setloading(true);
    } catch (error) {
      console.log(error);
    }
  };

  const changeWhatWeDo = (wid) => {
    navigate(`/whatwedo/${wid}`);
    id = wid;
    FetchPost();
  };

  const Whatwedo = async () => {
    try {
      let response = await axios.get("/admin/getactiveservice");
      console.log("service", response.data);
      setservice_data(response.data.services);
      setService_first(response.data.services[0]);
      setload(true);
      console.log("service_first", service_first);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="whatwedo">
      <section className="banner_chaild">
        <div
          className="container"
          style={{
            marginRight: 0,
            marginLeft: 0,
            width: "100%",
            padding: 0,
            maxWidth: "100%",
          }}
        >
          <div
            className="dd-titlediv container"
            style={{ marginBottom: "40px" }}
          >
            <h1
              className={
                currentLang === "en"
                  ? "title_head_1 pd20 wwe-title"
                  : " title_head_1_ar pd20 wwe-title"
              }
            >
              {t("What we do")}
            </h1>
            <h1 className="tpdb-title wwe-title">{t("What we do")}</h1>
          </div>
        </div>
      </section>

      <section>
        <div className="row">
          {service_data.map((item) => {
            return (
              <div
                className="item col-md-3 col-6 no-gutters whatwedo-items"
                onClick={() => {
                  changeWhatWeDo(item.id);
                }}
              >
                <div
                  className="whatwe_mains what_wetext whatwedo_divdiv"
                  id={item.id}
                  key={item.id}
                >
                  <img
                    alt=""
                    src={`${baseurl}${item.image}`}
                    style={{ height: "auto", width: "100%" }}
                  />
                  <p className="whatwe_title wwe-title">
                    {currentLang === "en" ? item.title : item.title_ar}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </section>

      <section className="text-center" id="content1">
        {!loading ? (
          <div className="background_service">
            <img alt="" src={`${baseurl}${service_first.image}`} width="100%" />
            <div className="container service_child">
              <div className="row whatwedo_mains">
                <div className="col-md-6" style={{ position: "relative" }}>
                  <h1 className="smallwhite-title whatwedo-bigtitle wwe-title">
                    {currentLang === "en"
                      ? service_first.title
                      : service_first.title_ar}
                  </h1>
                </div>
                <div className="col-md-6 scrolling">
                  <p className="wwd-text">
                    {currentLang === "en"
                      ? service_first.content
                      : service_first.content_ar}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="background_service">
            <img
              alt=""
              src={`${baseurl}${whatwedo_data.image}`}
              width="100%"
              className="wwd-img"
            />
            <div className="container service_child">
              <div className="row whatwedo_mains ">
                <div className="col-md-6" style={{ position: "relative" }}>
                  <h1 className="wwe-title wwd-title">
                    {currentLang === "en"
                      ? whatwedo_data.title
                      : whatwedo_data.title_ar}
                  </h1>
                </div>
                <div className="col-md-6 scrolling">
                <p className="wwd-text">
                    {currentLang === "en"
                      ? whatwedo_data.content
                      : whatwedo_data.content_ar}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default Whatwedo;
